<template>
  <div class="confirm">
    <h2>{{ $t('auth.welcome') }}</h2>
    <form @submit.prevent="authenticate" v-if="confirmed">
      <div class="row">
        <loading-notification :show="loading && !preparing" />
        <loading-notification :show="preparing">
          <span>{{ $t('notifications.login.preparing') }}</span>
        </loading-notification>
        <error-notification :show="invalid">
          <span>{{ $t('notifications.confirm_account.error') }}</span>
        </error-notification>
      </div>
      <legend>
        <strong>{{ $t('auth.confirm.email_used') }}</strong>
        {{ email }}
      </legend>
      <h6>{{ $t('auth.confirm.form', {min: 8}) }}</h6>
      <va-input
        color="info"
        v-model="form.password.value"
        :type="passwordFieldType"
        :label="$t('auth.password')"
        :error="!!form.password.errors.length"
        :error-messages="translatedErrors(form.password.errors)"
        @blur="validate('password')"
      >
        <va-button
          type="button"
          slot="append"
          style="margin-right: 0;"
          :icon="passwordVisible ? 'fa fa-eye-slash' : 'fa fa-eye'"
          @click="showPassword()"
          flat
          small
        />
      </va-input>
      <va-input
        color="info"
        v-model="form.confirm_password.value"
        :type="passwordFieldType"
        :label="$t('users.inputs.confirmPasswordInput')"
        :error="!!form.confirm_password.errors.length"
        :error-messages="translatedErrors(form.confirm_password.errors)"
        @blur="validate('confirm_password')"
      >
        <va-button
          type="button"
          slot="append"
          style="margin-right: 0;"
          :icon="passwordVisible ? 'fa fa-eye-slash' : 'fa fa-eye'"
          @click="showPassword()"
          flat
          small
        />
      </va-input>
      <div class="row justify--center">
        <va-button type="submit" class="my-0">{{ $t('auth.reset_password') }}</va-button>
      </div>
    </form>
    <div v-else-if="rejected">
      <h6>{{ $t('auth.confirm.bad_token') }}</h6>
      <router-link class="link" :to="{name: 'login'}">{{ $t('auth.confirm.back_to_login') }}</router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { validatorMixin } from '@/services/validator'

export default {
  name: 'confirm-user',
  mixins: [validatorMixin],
  data () {
    return {
      loading: false,
      invalid: false,
      noMatch: false,
      preparing: false,
      confirmed: false,
      rejected: false,
      passwordVisible: false,
      token: null,
      email: null,
      form: {
        password: {
          value: '',
          validate: {
            required: true,
            minLength: 8,
          },
          errors: [],
        },
        confirm_password: {
          value: '',
          validate: {
            required: true,
            minLength: 8,
            equalsTo: {
              field: 'password',
              msg: '',
            },
          },
          errors: [],
        },
      },
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'currentLocale']),
    passwordFieldType () {
      return this.passwordVisible ? 'text' : 'password'
    },
  },
  watch: {
    currentLocale (val) {
      this.validateAll()
    },
  },
  created () {
    delete this.$http.defaults.headers.common.Authorization
    this.loading = false
    this.checkToken()
  },
  methods: {
    showPassword () {
      this.passwordVisible = !this.passwordVisible
    },
    async checkToken () {
      const token = this.urldecode(this.$route.query.token)

      this.rejected = false
      this.confirmed = false
      this.loading = true

      let request = null
      try {
        request = await this.$http.get('auth/verify/' + token)
      } catch (error) {
        console.log('Error confirming', error)
        this.loading = false
        this.confirmed = false
        this.rejected = true
        return
      }

      this.loading = false
      this.rejected = false
      this.confirmed = true
      const data = request.data.data
      this.token = data.token
      this.email = data.email
    },
    urldecode (url) {
      return decodeURIComponent(url.replace(/\+/g, ' '))
    },
    async authenticate () {
      this.loading = true
      this.invalid = false
      const data = {
        token: this.token,
        password: this.getFormValue('password'),
      }

      let request = false
      try {
        request = await this.$http.post('auth/reset-password', data)
      } catch (error) {
        this.confirmFailed(error)
        return
      }
      this.confirmSuccess(request.data)
    },
    confirmSuccess (req) {
      this.showToast(this.$t('auth.confirm.success'), {
        icon: 'fa-check',
        position: 'top-right',
        duration: 7200,
      })

      this.preparing = true
      this.$router.push({ name: 'login' })
    },
    confirmFailed (error) {
      let message = 'notifications.unknown'
      console.log('error:', error)
      if (error && error.request.status !== 400) {
        message = 'notifications.confirm_account.fail'
      }

      if (error.request.status === 401) {
        this.invalid = true
      }

      this.loading = false
      this.showToast(this.$t(message), { icon: 'fa-times' })
      this.$store.dispatch('logout')
      delete this.$http.defaults.headers.common.Authorization
    },
  },
}
</script>
